import React from 'react'
import { Helmet } from "react-helmet"
import Layout from '../components/Layout'
import Header from '../components/Header'
import Distribution from '../components/Distribution'
import Footer from '../components/Footer'

class DistributionPage extends React.Component{
    render(){
        return(
            <Layout>
               <Helmet>
                <meta charset="utf-8"/>
                <title>WALTZ | Distribucija</title>
                <meta name="description" content="Naši proizvodi se nalaze na policama mnogih supermarketa u regionu i širom sveta. Za to su zaduženi naši distributeri."/>
                <meta name="image" content="https://waltz.rs/waltz_seo_image.jpg"/>
                <meta itemprop="name" content="WALTZ | Distribucija"/>
                <meta itemprop="description" content="Naši proizvodi se nalaze na policama mnogih supermarketa u regionu i širom sveta. Za to su zaduženi naši distributeri."/>
                <meta itemprop="image" content="https://waltz.rs/waltz_seo_image.jpg"/>
                <meta name="og:title" content="WALTZ | Distribucija"/>
                <meta name="og:description" content="Naši proizvodi se nalaze na policama mnogih supermarketa u regionu i širom sveta. Za to su zaduženi naši distributeri."/>
                <meta name="og:image" content="https://waltz.rs/waltz_seo_image.jpg"/>
                <meta name="og:url" content="https://waltz.rs/distribucija"/>
                <meta name="og:site_name" content="WALTZ | Distribucija"/>
                <meta name="og:locale" content="sr"/>
                <meta name="og:type" content="website"/>
                </Helmet>
              <Header />
              <Distribution />
              <Footer />
            </Layout>
        );
    }
}
  export default DistributionPage;
  